import React from 'react';
import { Image, Box, Flex } from '@chakra-ui/react';
import carb from './carb_logo.png';

export const Header = () => (
  <Flex
    width={'full'}
    justifyContent={{ base: 'left', md: 'center' }}
    height='60px'
    bgColor='white'
  >
    <Flex
      marginX={2}
      w={{ base: 'full', md: '8xl' }}
      justifyContent={{ base: 'center', md: 'left' }}
      alignItems='center'
    >
      <Image
        src={carb}
        height='28px'
        width='min-content'
        objectFit={'contain'}
      />
    </Flex>
  </Flex>
);
