import React from 'react';
import { Image, Box, Flex, Heading, Text } from '@chakra-ui/react';
import carb from './carb_logo.png';

export const Features = () => (
  <Flex
    width={'full'}
    justifyContent={{ base: 'left', md: 'center' }}
    bgColor='gray.900'
    color='white'
  >
    <Flex
      marginX={2}
      w={{ base: 'full', md: '8xl' }}
      justifyContent={'space-between'}
      alignItems='center'
    >
      <Flex direction='column' margin='20px'>
        <Heading size={'sm'} marginBottom='10px'>
          Lorem ipsum
        </Heading>
        <Text>
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod
          tempor
        </Text>
      </Flex>
      <Flex direction='column' margin='20px'>
        <Heading size={'sm'} marginBottom='10px'>
          Dolor sit amet
        </Heading>
        <Text>
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod
          tempor
        </Text>
      </Flex>
      <Flex direction='column' margin='20px'>
        <Heading size={'sm'} marginBottom='10px'>
          Consectetur
        </Heading>
        <Text>
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod
          tempor
        </Text>
      </Flex>
      <Flex direction='column' margin='20px'>
        <Heading size={'sm'} marginBottom='10px'>
          Sed do eiusmod
        </Heading>
        <Text>
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod
          tempor
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
