import {
  Stack,
  Flex,
  Button,
  Text,
  VStack,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';

export const Hero = () => (
  <Flex w={'full'}>
    <Box
      zIndex={'-1'}
      w={'full'}
      h={'500px'}
      position='absolute'
      as='video'
      autoPlay
      muted
      loop
      src='https://assets.mixkit.co/videos/preview/mixkit-forest-in-the-mountains-aerial-view-1764-large.mp4'
      //src='/mixkit-highway-traffic-seen-through-drone-611.mp4'
      poster='https://mixkit.imgix.net/videos/preview/mixkit-forest-in-the-mountains-aerial-view-1764-0.jpg'
      alt=''
      objectFit='cover'
    />
    <Flex
      w={'full'}
      h={'500px'}
      bgGradient={{
        base: 'linear(to-b, white, transparent)',
        md: 'linear(to-r, white, transparent 50%)',
      }}
      justifyContent='center'
    >
      <Flex
        marginX={2}
        marginTop={40}
        w={{ base: 'full', md: '8xl' }}
        justifyContent='left'
      >
        <Text
          fontWeight={700}
          lineHeight={1.2}
          fontSize={{ base: '3xl', md: '4xl' }}
          fontFamily="'Noto Serif', serif"
          color={'blackAlpha.900'}
          maxW='500px'
        >
          Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod
          tempor
        </Text>
      </Flex>
    </Flex>
  </Flex>
);
