import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';
import { Header } from './Header';
import { Hero } from './Hero';
import { Features } from './Features';

function App () {
  return (
    <ChakraProvider theme={theme}>
      <Header />
      <Hero />
      <Features />
    </ChakraProvider>
  );
}

export default App;
